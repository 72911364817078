import React from 'react';
import './SwiperImages.css';

export class SwiperImages extends React.Component {

    render() {
        const images = this.props.images || [];

        return (
            <div
                className='swiper-images'>
                {images.map((image, index) => (
                    <img
                        key={index}
                        src={image.src}
                        alt={image.alt}
                    />
                ))}
            </div>
        );
    }
}
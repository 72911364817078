export const prodottoImages = [
    {
        src: require('./images/gallery/prodotto/1_Moser_corsa donna_DSC5854.jpeg'),
        alt: ''
    },
    {
        src: require('./images/gallery/prodotto/2_Moser_corsa donna_DSC5739.jpeg'),
        alt: ''
    },
    {
        src: require('./images/gallery/prodotto/3_Moser_corsa donna_DSC5861.jpeg'),
        alt: ''
    },
    {
        src: require('./images/gallery/prodotto/4_Bianchi_Rekord_838_Celeste__DSC5680_1.jpeg'),
        alt: ''
    },
    {
        src: require('./images/gallery/prodotto/5_Bianchi_Rekord_838_Celeste__DSC5749.jpeg'),
        alt: ''
    },
    {
        src: require('./images/gallery/prodotto/6_Peugeut_DSC5324.jpeg'),
        alt: ''
    },
    {
        src: require('./images/gallery/prodotto/7_Gialla-Verde_S.S._DSC5279 copia.jpeg'),
        alt: ''
    },
    {
        src: require('./images/gallery/prodotto/8_Gialla-Verde_S.S._DSC5482.jpeg'),
        alt: ''
    },
    {
        src: require('./images/gallery/prodotto/9_Ganna 100_uomo_DSC6696_1.jpeg'),
        alt: ''
    },
    {
        src: require('./images/gallery/prodotto/10_Graziella_Fatb_DSC6202.jpeg'),
        alt: ''
    },
    {
        src: require('./images/gallery/prodotto/11_Graziella_Fatb_DSC6209.jpeg'),
        alt: ''
    },
    {
        src: require('./images/gallery/prodotto/12_Graziella_Fatb_DSC6248.jpeg'),
        alt: ''
    },
    {
        src: require('./images/gallery/prodotto/14_DSC_9791.jpeg'),
        alt: ''
    },
    {
        src: require('./images/gallery/prodotto/15_DSC_9752.jpeg'),
        alt: ''
    },
    {
        src: require('./images/gallery/prodotto/16_DSC_9738-Modifica.jpeg'), 
        alt: ''
    },
    {
        src: require('./images/gallery/prodotto/17_DSC_9786.jpeg'),
        alt: ''
    },
    {
        src: require('./images/gallery/prodotto/18_DSC_9718.jpeg'),
        alt: ''
    },
    {
        src: require('./images/gallery/prodotto/19_DSC_9643.jpeg'),
        alt: ''
    },
    {
        src: require('./images/gallery/prodotto/CMF_1.jpeg'),
        alt: ''
    },
    {
        src: require('./images/gallery/prodotto/CMF_2.jpeg'),
        alt: ''
    },
    {
        src: require('./images/gallery/prodotto/CMF_3.jpeg'),
        alt: ''
    },
    {
        src: require('./images/gallery/prodotto/TRENDS_1.jpeg'),
        alt: ''
    },
    {
        src: require('./images/gallery/prodotto/TRENDS_2.jpeg'),
        alt: ''
    },
    {
        src: require('./images/gallery/prodotto/UX.jpeg'),
        alt: ''
    }
];

export const interiorImages = [
    {
        src: require('./images/gallery/interior/1_Piscina Giotti-13.jpeg'),
        alt: ''
    },
    {
        src: require('./images/gallery/interior/2_Moser_Gartenhotel_DSC2055.jpeg'),
        alt: ''
    },
    {
        src: require('./images/gallery/interior/3_Moser_Gartenhotel_DSC2643-HDR.jpeg'),
        alt: ''
    },
    {
        src: require('./images/gallery/interior/4_Piscina Giotti-12.jpeg'),
        alt: ''
    },
    {
        src: require('./images/gallery/interior/5_Moser_Gartenhotel_DSC2503-HDR.jpeg'),
        alt: ''
    },
    {
        src: require('./images/gallery/interior/6_sala 1.jpeg'),
        alt: ''
    },
    {
        src: require('./images/gallery/interior/7_sala 5.jpeg'),
        alt: ''
    },
    {
        src: require('./images/gallery/interior/8_sala 2.jpeg'),
        alt: ''
    },
    {
        src: require('./images/gallery/interior/9_camera Padr 2.jpeg'),
        alt: ''
    },
    {
        src: require('./images/gallery/interior/10_camera ospiti 1.jpeg'),
        alt: ''
    },
    {
        src: require('./images/gallery/interior/12_DSC9363-Modifica-2.jpeg'),
        alt: ''
    },
    {
        src: require('./images/gallery/interior/13_LegalLab_DSC9322.jpeg'),
        alt: ''
    },
    {
        src: require('./images/gallery/interior/14_DETTAGLIO8.jpeg'),
        alt: ''
    },
    {
        src: require('./images/gallery/interior/15_PRIMO_camera1a.jpeg'),
        alt: ''
    },
    {
        src: require('./images/gallery/interior/16_TERRA_salone6.jpeg'),
        alt: ''
    }
];

export const modaRitrattiImages = [
    {
        src: require('./images/gallery/moda-e-ritratti/1_DSC1568.jpeg'),
        alt: ''
    },
    {
        src: require('./images/gallery/moda-e-ritratti/2_DSC0402.jpeg'),
        alt: ''
    },
    {
        src: require('./images/gallery/moda-e-ritratti/3_DSC2743.jpeg'),
        alt: ''
    },
    {
        src: require('./images/gallery/moda-e-ritratti/4_DSC1097.jpeg'),
        alt: ''
    },
    {
        src: require('./images/gallery/moda-e-ritratti/5_DSC0911.jpeg'),
        alt: ''
    },
    {
        src: require('./images/gallery/moda-e-ritratti/6_Aline Tomasini__DSC4021 copia.jpeg'),
        alt: ''
    },
    {
        src: require('./images/gallery/moda-e-ritratti/8_Giacomo Santi_DSC6422.jpeg'),
        alt: ''
    },
    {
        src: require('./images/gallery/moda-e-ritratti/9_Lidia Ginevra_DSC1702.jpeg'),
        alt: ''
    },
    {
        src: require('./images/gallery/moda-e-ritratti/10_Sofia_Ravanelli__DSC5590 copia.jpeg'),
        alt: ''
    },
    {
        src: require('./images/gallery/moda-e-ritratti/11_DSC00882.jpeg'),
        alt: ''
    },
    {
        src: require('./images/gallery/moda-e-ritratti/12_DSC00956.jpeg'),
        alt: ''
    },
    {
        src: require('./images/gallery/moda-e-ritratti/13_DSC00923.jpeg'),
        alt: ''
    },
    {
        src: require('./images/gallery/moda-e-ritratti/15_DSC03340.jpeg'),
        alt: ''
    },
    {
        src: require('./images/gallery/moda-e-ritratti/16_DSC03345.jpeg'),
        alt: ''
    },
    {
        src: require('./images/gallery/moda-e-ritratti/17_DSC03367.jpeg'),
        alt: ''
    }
];

export const reportageAziendali = [
    {
        src: require('./images/gallery/reportage-aziendali/1.jpeg'),
        alt: ''
    },
    {
        src: require('./images/gallery/reportage-aziendali/2.jpeg'),
        alt: ''
    },
    {
        src: require('./images/gallery/reportage-aziendali/3_HBI_DSC7004-Modifica.jpeg'),
        alt: ''
    },
    {
        src: require('./images/gallery/reportage-aziendali/4_DSC7265.jpeg'),
        alt: ''
    },
    {
        src: require('./images/gallery/reportage-aziendali/5_HSE_DSC7258.jpeg'),
        alt: ''
    },
    {
        src: require('./images/gallery/reportage-aziendali/6_HSE_DSC7332.jpeg'),
        alt: ''
    },
    {
        src: require('./images/gallery/reportage-aziendali/7_HSE_DSC7324.jpeg'),
        alt: ''
    },
    {
        src: require('./images/gallery/reportage-aziendali/8_HBI_DSC8913.jpeg'),
        alt: ''
    },
    {
        src: require('./images/gallery/reportage-aziendali/9_HBI_DSC6910.jpeg'),
        alt: ''
    },
    {
        src: require('./images/gallery/reportage-aziendali/10_HBI_DSC7007.jpeg'),
        alt: ''
    }
];

export const editorialImages = [
    {
        src: require('./images/gallery/editorial/1_DSC8909_1.jpeg'),
        alt: ''
    },
    {
        src: require('./images/gallery/editorial/2_DSC8927_1.jpeg'),
        alt: ''
    },
    {
        src: require('./images/gallery/editorial/3_DSC8937_1.jpeg'),
        alt: ''
    },
    {
        src: require('./images/gallery/editorial/4_DSC8938_1.jpeg'),
        alt: ''
    },
    {
        src: require('./images/gallery/editorial/5_DSC8939_1.jpeg'),
        alt: ''
    },
    {
        src: require('./images/gallery/editorial/6_DSC8945_1.jpeg'),
        alt: ''
    },
    {
        src: require('./images/gallery/editorial/7_DSC8950_1.jpeg'),
        alt: ''
    },
    {
        src: require('./images/gallery/editorial/8_DSC8968_1.jpeg'),
        alt: ''
    },
    {
        src: require('./images/gallery/editorial/9_DSC8951_1.jpeg'),
        alt: ''
    },
    {
        src: require('./images/gallery/editorial/10_DSC8977_1.jpeg'),
        alt: ''
    },
    {
        src: require('./images/gallery/editorial/11_DSC8987_1.jpeg'),
        alt: ''
    },
    {
        src: require('./images/gallery/editorial/12_DSC9008_1.jpeg'),
        alt: ''
    },
    {
        src: require('./images/gallery/editorial/13_DSC9011_1.jpeg'),
        alt: ''
    },
    {
        src: require('./images/gallery/editorial/14_DSC9013_1.jpeg'),
        alt: ''
    },
    {
        src: require('./images/gallery/editorial/15_DSC9051_1.jpeg'),
        alt: ''
    },
    {
        src: require('./images/gallery/editorial/16_DSC9058_1.jpeg'),
        alt: ''
    },
    {
        src: require('./images/gallery/editorial/17_DSC9062_1.jpeg'),
        alt: ''
    },
    {
        src: require('./images/gallery/editorial/18_DSC9068_1.jpeg'),
        alt: ''
    },
    {
        src: require('./images/gallery/editorial/19_DSC9094_1.jpeg'),
        alt: ''
    }
];

export const brandingImages = [
    {
        src: require('./images/gallery/branding/1_certosinaA.png'),
        alt: ''
    },
    {
        src: require('./images/gallery/branding/2_certosinaB.jpg'),
        alt: ''
    },
    {
        src: require('./images/gallery/branding/3_certosinaC.jpeg'),
        alt: ''
    },
    {
        src: require('./images/gallery/branding/4_fratellanzaA.png'),
        alt: ''
    },
    {
        src: require('./images/gallery/branding/5_fratellanzaB.png'),
        alt: ''
    },
    {
        src: require('./images/gallery/branding/6_fratellanzaC.jpeg'),
        alt: ''
    },
    {
        src: require('./images/gallery/branding/7_fratellanzaD.jpeg'),
        alt: ''
    },
    {
        src: require('./images/gallery/branding/8_lupineriA.png'),
        alt: ''
    },
    {
        src: require('./images/gallery/branding/9_lupineriB.jpg'),
        alt: ''
    },
    {
        src: require('./images/gallery/branding/10_lupineriC.jpeg'),
        alt: ''
    },
    {
        src: require('./images/gallery/branding/11_sfacciatiA.png'),
        alt: ''
    },
    {
        src: require('./images/gallery/branding/12_sfacciatiB.jpg'),
        alt: ''
    },
    {
        src: require('./images/gallery/branding/13_sfacciatiC.jpeg'),
        alt: ''
    }
];
import './App.css';
import React from 'react';
import { useTranslation, Trans } from "react-i18next";
import { SwiperImages } from './components/SwiperImages'; 

import { prodottoImages, brandingImages, editorialImages, interiorImages, modaRitrattiImages, reportageAziendali } from './swiperImagesConfig';

import Copertina from './images/copertina.jpeg';

import Icon1 from './images/ICON_1.svg';
import Icon2 from './images/ICON_2.svg';
import Icon3 from './images/ICON_3.svg';

import Logo from './images/logo.png';

import Logo1 from './images/BICICLETTERIA.svg';
import Logo2 from './images/Fleich Design.png';
import Logo3 from './images/FA_FESTA.svg';
import Logo4 from './images/HOTEL_MOSER.png';
import Logo5 from './images/LEGALLAB.svg';
import Logo6 from './images/ISIA FIRENZE.svg';
import Logo7 from './images/LUPINERI.png';
import Logo8 from './images/NOI TECHPARK.png';
import Logo9 from './images/AIRBNB.png';

function App() {
    const { t, i18n } = useTranslation();

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    const isLanguage = (lng) => {
        return i18n.language === lng;
    };

    return (
        <React.Fragment>
            <nav className="navbar navbar-dark navbar-expand-lg fixed-top">
                <div className="container py-2 px-3 mx-auto">
                    <img className='navbar-logo' src={Logo} alt="" />
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <div className="btn-group-container d-flex ms-auto">
                            <div className="btn-group selector-lang" role="group" aria-label="Basic radio toggle button group">
                                <input type="radio" className="btn-check" name="btnradio" id="btnradio1" autoComplete="off" onChange={() => changeLanguage('en')} checked={isLanguage('en')}/>
                                <label className="btn btn-outline-primary" htmlFor="btnradio1">ENG</label>

                                <input type="radio" className="btn-check" name="btnradio" id="btnradio2" autoComplete="off" onChange={() => changeLanguage('de')} checked={isLanguage('de')}/>
                                <label className="btn btn-outline-primary" htmlFor="btnradio2">DE</label>

                                <input type="radio" className="btn-check" name="btnradio" id="btnradio3" autoComplete="off" onChange={() => changeLanguage('it')} checked={isLanguage('it')}/>
                                <label className="btn btn-outline-primary" htmlFor="btnradio3">IT</label>
                            </div>
                        </div>
                        <div className='d-lg-none text-center'>
                            info@fosstudio.it<br />+39 339 8990225
                        </div>
                        <div className='position-absolute d-lg-block d-none translate-middle top-50 start-50'>
                            info@fosstudio.it  |  +39 339 8990225
                        </div>
                    </div>
                </div>
            </nav>
            <div className="App">
                <section className='section-introduzione'>
                    <div className="container py-4 px-3 mx-auto">

                        <div className='container-logo'>
                            <img src={Logo} alt='' />
                        </div>

                        <p>
                            {t('intro.text1')}
                        </p>
                        <p>
                            {t('intro.text2')}
                        </p>

                        <img src={Copertina} alt="" />

                        <div className="row mx-auto gx-5 gy-5">
                            <div className="col-md">
                                <p className="card-title">Filippo Viciani</p>
                                <p className="card-subtitle mb-3">{t('bio.filippo.job')}</p>
                                <p className="card-text">{t('bio.filippo.description')}</p>
                            </div>
                            <div className="col-md">
                                <p className="card-title">Irene Lombardi</p>
                                <p className="card-subtitle mb-3">{t('bio.irene.job')}</p>
                                <p className="card-text">{t('bio.irene.description')}</p>
                            </div>
                            <div className="col-md">
                                <p className="card-title">Daniel von Johnston</p>
                                <p className="card-subtitle mb-3">{t('bio.daniel.job')}</p>
                                <p className="card-text">{t('bio.daniel.description')}</p>
                            </div>
                        </div>

                    </div>
                </section>

                <section className='section-images'>
                    <h1 className='ps-md-5 ps-3'>{t('categorie.title1')}</h1>
                    <SwiperImages images={prodottoImages}/>

                    <h1 className='ps-md-5 ps-3'>{t('categorie.title2')}</h1>
                    <SwiperImages images={interiorImages}/>
                  
                    <h1 className='ps-md-5 ps-3'>{t('categorie.title3')}</h1>
                    <SwiperImages images={modaRitrattiImages}/>

                    <h1 className='ps-md-5 ps-3'>{t('categorie.title4')}</h1>
                    <SwiperImages images={reportageAziendali}/>

                    <h1 className='ps-md-5 ps-3'>{t('categorie.title5')}</h1>
                    <SwiperImages images={editorialImages}/>

                    <h1 className='ps-md-5 ps-3'>{t('categorie.title6')}</h1>
                    <SwiperImages images={brandingImages}/>

                </section>
                <section className='section-services'>
                    <div className="container py-4 px-3 mx-auto">
                        <p>{t('servizi.text1')}</p>
                        <div className='row g-lg-5 gy-5 mx-auto'>
                            <div className='w-100'></div>
                            <div className="col-lg ps-lg-0">
                                <h1>{t('servizi.service1.tile')}</h1>
                                <p>{t('servizi.service1.text')}</p>
                            </div>
                            <div className="col-lg">
                                <h1>{t('servizi.service2.tile')}</h1>
                                <p>{t('servizi.service2.text')}</p>
                            </div>
                            <div className="col-lg pe-lg-0">
                                <h1>{t('servizi.service3.tile')}</h1>
                                <p>{t('servizi.service3.text')}</p>
                            </div>
                            <div className='w-100 d-none d-lg-block'></div>
                            <div className="col-lg ps-lg-0">
                                <h1>{t('servizi.service4.tile')}</h1>
                                <p>{t('servizi.service4.text')}</p>
                            </div>
                            <div className="col-lg">
                                <h1>{t('servizi.service5.tile')}</h1>
                                <p>{t('servizi.service5.text')}</p>
                            </div>
                            <div className="col-lg pe-lg-0">
                                <h1>{t('servizi.service6.tile')}</h1>
                                <p>{t('servizi.service6.text')}</p>
                            </div>
                        </div>
                    </div>

                    <div className='container-logo-clienti container-fluid px-5'>
                        <div>
                            <div className="container py-4 px-3 mx-auto">
                                <div className='row gx-5 gy-5 align-items-center mx-auto justify-content-center'>
                                    <div className='col p-md-0'><img style={{ width: '15rem'}} src={Logo3} alt="" /></div>
                                    <div className='col p-md-0'><img style={{ width: '8.9rem'}} src={Logo2} alt="" /></div>
                                    <div className='col p-md-0'><img style={{ width: '7.1rem' }} src={Logo1} alt="" /></div>
                                    <div className='col p-md-0'><img style={{ width: '16.5rem'}} src={Logo5} alt="" /></div>
                                    <div className='col p-md-0'><img style={{ width: '12.5rem'}} src={Logo4} alt="" /></div>
                                    <div className='w-100'></div>
                                    <div className='col p-md-0'><img style={{ width: '18.75rem'}} src={Logo6} alt="" /></div>
                                    <div className='col p-md-0'><img style={{ width: '12.9rem'}} src={Logo7} alt="" /></div>
                                    <div className='col p-md-0'><img style={{ width: '12.25rem' }} src={Logo8} alt="" /></div>
                                    <div className='col p-md-0'><img style={{ width: '14.6rem'}} src={Logo9} alt="" /></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container py-4 px-3 mx-auto">
                        <p><Trans i18nKey="fos.text1"></Trans></p>
                        <p>
                            <Trans i18nKey="fos.text2"></Trans>
                        </p>
                        <div className='row gx-5 align-items-center d-lg-flex d-none'>
                            <div className='col-lg'>
                                <img src={Icon1} alt="" style={{ width: '3.25rem' }} />
                            </div>
                            <div className='col-lg'>
                                <img src={Icon2} alt="" style={{ width: '8.3rem' }} />
                            </div>
                            <div className='col-lg'>
                                <img src={Icon3} alt="" style={{ width: '5rem' }} />
                            </div>
                        </div>
                        <div className='row mx-auto g-5'>
                            <div className='col-lg ps-lg-0'>
                                <img className='d-lg-none' src={Icon1} alt="" style={{ width: '3.25rem', marginBottom: '1.5rem' }} />
                                <h2>{t('valori.val1.title')}</h2>
                                <p>{t('valori.val1.text')}</p>
                            </div>
                            <div className='col-lg'>
                                <img className='d-lg-none' src={Icon2} alt="" style={{ width: '8.3rem', marginBottom: '1.5rem' }} />
                                <h2>{t('valori.val2.title')}</h2>
                                <p>{t('valori.val2.text')}</p>
                            </div>
                            <div className='col-lg pe-lg-0'>
                                <img className='d-lg-none' src={Icon3} alt="" style={{ width: '5rem', marginBottom: '1.5rem' }} />
                                <h2>{t('valori.val3.title')}</h2>
                                <p>{t('valori.val3.text')}</p>
                            </div>
                        </div>
                    </div>
                </section>

                <footer>
                    <div className='container flex-column h-100 d-flex justify-content-center align-items-center'>
                        <div className='row mx-auto gy-5 w-100 h-100'>
                            <div className='col-md d-flex align-items-center'>
                                <div className='mx-auto'>
                                    <h1><Trans i18nKey="footer.appuntamento.title"></Trans></h1>
                                    <p><Trans i18nKey="footer.appuntamento.text"></Trans></p>
                                </div>
                            </div>
                            <div className='col-md d-flex align-items-center'>
                                <div className='mx-auto'>
                                    <h1>{t('footer.contatti.title')}</h1>
                                    <p><b>info@fosstudio.it</b><br /><br />
                                        <b>+39 339 8990225</b><br /><br />
                                        {t('footer.contatti.text')}<br />
                                        +39 346 3320769<br />
                                        +39 331 4393176</p>
                                </div>
                            </div>
                        </div>
                        <div className='copyright py-2'>
                            © 2023 fos studio
                        </div>
                    </div>
                </footer>

            </div>
        </React.Fragment>
    );
}

export default App;
